import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { useNavigate } from "react-router-dom";
import { Carousel } from 'primereact/carousel';
import { Dialog } from 'primereact/dialog';
import { Accordion, AccordionTab } from 'primereact/accordion';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primeicons/primeicons.css';
import './index.scss';

export default function Home() {
    const socialProveImage = [
        { src: "/images/png/ps-3.jpeg"},
        { src: "/images/png/ps-4.jpeg"},
        { src: "/images/png/ps-5.jpeg"}
    ];
    const socialProveVideo = [
        { src: "/images/png/ps-1.mp4"},
        { src: "/images/png/ps-2.mp4"}
    ];

    const productTemplateImg = (product) => {
        return (
            <img src={product.src} className="c-imgs" alt="carousel-img" />
        );
    };

    const productTemplateVideo = (product) => {
        return (
            <video controls className="c-videos" autoPlay muted loop playsInline>
                <source src={product.src} type="video/mp4" />
                Seu navegador não suporta vídeos em HTML.
            </video>
        );
    };
    
    return (
        <div id="main-screen" className="w-full h-full flex flex-column align-items-center">
            <div
                className="wpp-button"
                onClick={() =>
                    window.open(
                    "https://api.whatsapp.com/send?phone=5521972493473&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto",
                    "_blank"
                    )
                }
            >
                <i className="pi pi-whatsapp"></i>
            </div>
            <div className="image-container">
                <div className="top-icon montserrat">
                    <img src="/images/png/logo-ebook.png" className="book-icon" width={100} alt="book-icon" />
                </div>
                <div className="montserrat content">
                    <div className="main-title">
                        <span>
                            Descubra como <span style={{ backgroundColor: "#ed935b", width: "fit-content", padding: "0px 10px" }}>aumentar as reservas</span> da sua hospedagem online e no automático
                        </span>
                    </div>
                    <div className="second-text">No nosso eBook, você encontrará TODAS as <strong>Estratégias Digitais para Hospedagens, os Segredos e suas Soluções</strong>. É sua chave para desbloquear o verdadeiro potencial da sua hospedagem.</div>
                    <div className="buttons">
                        <button
                            className="lm-button montserrat"
                            onClick={() => window.open("https://pay.hotmart.com/N95287093D?checkoutMode=10", "_blank") }
                        >
                            COMPRE AGORA
                            <i className="pi pi-shopping-cart"></i>
                        </button>
                        {/* <button className="bn-button montserrat">
                            COMPRAR
                            <i className="pi pi-shopping-cart"></i>
                        </button> */}
                    </div>
                </div>
                {/* <img src="/images/png/book-ofc-2.png" className="book-image" alt="book-image" /> */}
                <img src="/images/png/kindle-book-min.webp" className="book-image" alt="book-image" />
            </div>
            <div className="offer-session montserrat">
                <div className="main-box">
                    <div className="offer-text">
                        Se você deseja transformar a presença online da sua hospedagem, você está no lugar certo!
                    </div>
                    <div className="second-box">
                        <div className="text-1">Oferta exclusiva de</div>
                        <div className="text-2" style={{ position: "relative" }}>R$ 129,00
                            <div className="blink_me" style={{ position: "absolute", left: "0px", top: "20px",  width: "100%", height: "5px", backgroundColor: "#ea3f3f", transform: "rotate(6deg)", borderRadius: "4px" }}></div>
                        </div>
                        <div className="text-3">por</div>
                        <div className="text-4">R$ 59,90</div>
                        <div className="text-5">Oferta especial por tempo limitado</div>
                    </div>
                </div>
            </div>
            <div className="benefit-session montserrat">
                <div className="content">
                    <div className="header">
                        <div className="title">Beneficios claros</div>
                        <div className="sub-title">Confira os principais benefícios que você pode alcançar com o nosso ebook!</div>
                    </div>
                    <div className="body">
                        <div className="card">
                            <i className="pi pi-building"></i>
                            <div className="title">Mais Reservas, Mais Rápido</div>
                            <hr />
                            <div className="sub-title">Este eBook ensina você a atrair mais visitantes ao site da sua hospedagem e convertê-los em hóspedes. Isso significa mais reservas e mais lucro com passos simples e eficazes.</div>
                        </div>

                        <div className="card">
                            <i className="pi pi-eye"></i>
                            <div className="title">Aumente a visibilidade de sua hospedagem</div>
                            <hr />
                            <div className="sub-title">Mostraremos como usar Facebook, Instagram e outras redes sociais para fazer sua hospedagem ser visto por mais pessoas. Você aprenderá a postar o tipo certo de conteúdo que não apenas chama a atenção, mas também faz as pessoas quererem reservar com você.</div>
                        </div>
                        
                        <div className="card" style={{ marginTop: "20px" }}>
                            <i className="pi pi-dollar"></i>
                            <div className="title">Maximize seu Retorno sobre o Investimento</div>
                            <hr />
                            <div className="sub-title">Entenda como aplicar as melhores práticas do mercado para otimizar as reservas de sua hospedagem, gerando mais lucro com menos investimento.</div>
                        </div>

                        <div className="card" style={{ marginTop: "20px" }}>
                            <i className="pi pi-desktop"></i>
                            <div className="title">Domine as Técnicas de Marketing Digital</div>
                            <hr />
                            <div className="sub-title">Aprenda a usar ferramentas de marketing digital, como SEO e anúncios online, para potencializar a presença da sua hospedagem na internet.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="social-session montserrat">
                <div className="title">
                    Nosso eBook foi cuidadosamente desenvolvido com base em estudos de mercado recentes e o conhecimento acumulado de especialistas com anos de experiência no setor de hospitalidade
                    <div className="sub-title">
                        Ele fornece um conjunto abrangente de estratégias e ferramentas práticas projetadas para maximizar a presença online de qualquer estabelecimento hoteleiro. Ao escolher nosso eBook, você estará se equipando com as mais eficientes e atualizadas técnicas de marketing digital que foram adaptadas especificamente para a indústria de hospedagem
                    </div>
                </div>
                <div className="medal-icon">
                    <img src="/images/png/star-medal.png" className="normal-medal" width={"100%"} alt="normal-medal" />
                    <img src="/images/png/star-medal.png" className="shadow-medal" width={"100%"} alt="shadow-medal" />
                </div>
                <div className="prints-container">
                    <div className="title playwrite-us-modern-400">
                        Quem testou, comprovou e indica!
                    </div>
                    <div className="prints">
                        <img src="/images/png/ps-3.jpeg" className="print-1" alt="print-1" />
                        <video controls className="print-2">
                            <source src="/images/png/ps-1.mp4" type="video/mp4" />
                            Seu navegador não suporta vídeos em HTML.
                        </video>
                        {/* <img src="/images/png/print-1.png" className="print-2" alt="print-2" /> */}
                        <img src="/images/png/ps-4.jpeg" className="print-3" alt="print-3" />
                        <video controls className="print-4">
                            <source src="/images/png/ps-2.mp4" type="video/mp4" />
                            Seu navegador não suporta vídeos em HTML.
                        </video>
                        {/* <img src="/images/png/print-1.png" className="print-4" alt="print-4" /> */}
                        <img src="/images/png/ps-5.jpeg" className="print-5" alt="print-5" />
                    </div>
                    <div className="carousel">
                        <Carousel value={socialProveImage} numScroll={1} numVisible={1} itemTemplate={productTemplateImg} />
                    </div>
                    <div className="carousel">
                        <Carousel value={socialProveVideo} numScroll={1} numVisible={1} itemTemplate={productTemplateVideo} />
                    </div>
                </div>
            </div>
            <div className="guarantee-session montserrat">
                <div className="guarantee-container">
                    <div className="offer">
                        <div style={{ position: "absolute", width: "100%", height: "100%", top: "0px", left: "0px", border: "2px solid #ed935b", borderRadius: "10px", backgroundColor: "#ffffffd8" }}></div>
                        <img src="/images/png/img-produto.png" className="offer-img" alt="offer-img" />
                        <div className="title">Aproveite a nossa oferta especial por tempo limitado</div>
                        <div className="text-1">
                            <br />
                            Não perca tempo de virar a chave da sua hospedagem. De o primeiro passo ao grande sucesso do seu negócio. Compre <strong>O GUIA DEFINITIVO PARA HOSPEDAGENS NO MEIO DIGITAL</strong> e comece hoje mesmo a realizar o sonho da sua hospedagem lotada!
                        </div>
                        <span className="offer-spans">De <span style={{ textDecoration: "line-through", fontSize: "21px" }}>R$ 129,00</span></span>
                        <span className="offer-spans">Por apenas:</span>
                        <span className="offer-spans">8x de <strong style={{ fontSize: "35px" }}>R$ 8,49</strong></span>
                        <span className="offer-spans">Ou R$ 59,90 à vista</span>

                        <button
                            className="bn-button montserrat" style={{ marginTop: "30px" }}
                            onClick={() => window.open("https://pay.hotmart.com/N95287093D?checkoutMode=10", "_blank") }
                        >
                            Adquirir meu ebook agora!
                            <i className="pi pi-shopping-cart"></i>
                        </button>

                    </div>
                    <div className="guarantee">
                        <img src="/images/png/guarantee.png" className="guarantee-img" alt="guarantee-img" />
                        <div className="title">Compre sem risco!</div>
                        <div className="text-1">
                            Estamos tão confiantes no poder transformador do <strong>O GUIA DEFINITIVO PARA HOSPEDAGENS NO MEIO DIGITAL</strong> que oferecemos uma garantia de satisfação de 7 dias. Podendo pedir seu <strong>REEMBOLSO</strong> a <strong>QUALQUER</strong> momento.

                            <br /><br />
                            
                            <strong>Seu sucesso é nossa prioridade!</strong>

                            <br /><br />

                            Com nossa garantia de 7 dias, você pode experimentar sem preocupações e ver por si mesmo como ele pode transformar sua hospedagem em uma verdadeira Liderança no mercado.

                            <br /><br />

                            Compre Agora e comece sua jornada rumo à independência financeira, sabendo que você está protegido!
                        </div>
                    </div>
                </div>
            </div>

            <div className="faq-session montserrat">
                <div className="card">
                    <div className="title">Perguntas frequentes <i className="pi pi-question-circle"></i></div>
                    <Accordion activeIndex={null}>
                        <AccordionTab header="E se eu não tiver experiência prévia em marketing digital?">
                            <p className="m-0">
                                Não se preocupe! Nosso eBook foi desenhado para usuários de todos os níveis de experiência. Você encontrará instruções detalhadas e passo a passo que facilitam a aplicação das estratégias, mesmo que você seja um iniciante no marketing digital.
                            </p>
                        </AccordionTab>
                        <AccordionTab header="Quanto tempo leva para ver os resultados?">
                            <p className="m-0">
                                Alguns clientes começam a ver resultados em poucas semanas, mas o tempo pode variar dependendo de vários fatores, como o nível de engajamento e a consistência na aplicação das estratégias.
                            </p>
                        </AccordionTab>
                        <AccordionTab header="E se as estratégias não funcionarem para o meu tipo de hospedagem?">
                            <p className="m-0">
                                As técnicas incluídas no eBook são amplamente aplicáveis, seja você dono de um pequeno Airbnb, uma pousada boutique ou um grande hotel. Elas foram testadas em diferentes tipos de hospedagens com sucesso comprovado, adaptando-se a diversas necessidades e contextos.
                            </p>
                        </AccordionTab>
                        <AccordionTab header="Preciso de ferramentas ou softwares caros para implementar essas estratégias?">
                            <p className="m-0">
                                Não. A maioria das estratégias pode ser implementada com recursos que você já possui ou com ferramentas gratuitas ou de baixo custo disponíveis no mercado. Nosso eBook também oferece recomendações de ferramentas acessíveis para ajudá-lo a começar.
                            </p>
                        </AccordionTab>
                        <AccordionTab header="E se eu precisar de ajuda adicional durante a implementação?">
                            <p className="m-0">
                                Oferecemos suporte contínuo através de nosso canal de atendimento ao cliente e acesso a uma comunidade online onde você pode fazer perguntas, compartilhar sucessos e aprender com as experiências de outros hoteleiros que também estão utilizando o eBook.
                            </p>
                        </AccordionTab>
                    </Accordion>
                </div>
            </div>

        </div>
    )
}